import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import { Sidebar2023 } from 'views/SchoolProgram2023/components';
import Sidebar2024 from '../SchoolProgram2023/components/Sidebar2024';
import Archive2022 from '../SchoolProgram2023/components/Archive2022';


const ChildrensDay2024 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });


    const [config, setConfig] = useState({ base_image_url: '' });
    useEffect(() => {
        fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
            .then(response => response.json())
            .then(data => setConfig(data))
            .catch(error => console.error('Error fetching config:', error));
    }, []);

    const p1 = `${config.base_image_url}/home/events-activities/school-programs/Childrensday2024/1.webp`;
    const p2 = `${config.base_image_url}/home/events-activities/school-programs/Childrensday2024/2.webp`;
    const p3 = `${config.base_image_url}/home/events-activities/school-programs/Childrensday2024/3.webp`;
    const p4 = `${config.base_image_url}/home/events-activities/school-programs/Childrensday2024/4.webp`;
    const p5 = `${config.base_image_url}/home/events-activities/school-programs/Childrensday2024/5.webp`;
    const p6 = `${config.base_image_url}/home/events-activities/school-programs/Childrensday2024/6.webp`;
    const p7 = `${config.base_image_url}/home/events-activities/school-programs/Childrensday2024/7.webp`;
    const p8 = `${config.base_image_url}/home/events-activities/school-programs/Childrensday2024/8.webp`;
    const p9 = `${config.base_image_url}/home/events-activities/school-programs/Childrensday2024/9.webp`;
    const p10 = `${config.base_image_url}/home/events-activities/school-programs/Childrensday2024/10.webp`;
    const p11 = `${config.base_image_url}/home/events-activities/school-programs/Childrensday2024/11.webp`;
    const p12 = `${config.base_image_url}/home/events-activities/school-programs/Childrensday2024/12.webp`;
    const p13 = `${config.base_image_url}/home/events-activities/school-programs/Childrensday2024/13.webp`;
    const p14 = `${config.base_image_url}/home/events-activities/school-programs/Childrensday2024/14.webp`;
    const p15 = `${config.base_image_url}/home/events-activities/school-programs/Childrensday2024/15.webp`;
    const p16 = `${config.base_image_url}/home/events-activities/school-programs/Childrensday2024/16.webp`;
    const p17 = `${config.base_image_url}/home/events-activities/school-programs/Childrensday2024/17.webp`;
    const p18 = `${config.base_image_url}/home/events-activities/school-programs/Childrensday2024/18.webp`;



    const photos = [
        {
            src: p1,
            source: p1,
            rows: 1,
            cols: 1,
        },
        {
            src: p2,
            source: p2,
            rows: 1,
            cols: 1,
        },
        {
            src: p3,
            source: p3,
            rows: 1,
            cols: 1,
        },
        {
            src: p4,
            source: p4,
            rows: 1,
            cols: 1,
        },
        {
            src: p5,
            source: p5,
            rows: 1,
            cols: 1,
        },
        {
            src: p6,
            source: p6,
            rows: 1,
            cols: 1,
        },
        {
            src: p7,
            source: p7,
            rows: 1,
            cols: 1,
        },
        {
            src: p8,
            source: p8,
            rows: 1,
            cols: 1,
        },
        {
            src: p9,
            source: p9,
            rows: 1,
            cols: 1,
        },
        {
            src: p10,
            source: p10,
            rows: 1,
            cols: 1,
        },
        {
            src: p11,
            source: p11,
            rows: 1,
            cols: 1,
        },
        {
            src: p12,
            source: p12,
            rows: 1,
            cols: 1,
        },
        {
            src: p13,
            source: p13,
            rows: 1,
            cols: 1,
        },
        {
            src: p14,
            source: p14,
            rows: 1,
            cols: 1,
        },
        {
            src: p15,
            source: p15,
            rows: 1,
            cols: 1,
        },
        {
            src: p16,
            source: p16,
            rows: 1,
            cols: 1,
        },
        {
            src: p17,
            source: p17,
            rows: 1,
            cols: 1,
        },
        {
            src: p18,
            source: p18,
            rows: 1,
            cols: 1,
        },



    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                Children’s Day celebration
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                       Classes: Pre-Primary, 1 to 12     Date: 14 November 2024
                                </Typography>

                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                    The Children’s Day celebration at National Public School, Yeshwanthpur was a lively and memorable event, with teachers taking center stage in a variety of performances that brought joy to the students.
                                    <br></br>
                                    The day began with the inauguration of the 2024 school magazine – Repertoire, filled with pride and excitement. The event marked the unveiling of a beautiful collection of stories, artwork and achievements, showcasing the creativity and talent of our students. With great anticipation, we celebrated the launch of this new edition, a testament to our school’s spirit and imagination, paving the way for another year of inspiration and excellence.
                                    <br></br>
                                    The event further progressed with a musical performance by the students of Class 3D depicting the innocence of childhood followed by the teachers who sang a medley of cheerful children’s songs and popular tunes, filling the air with music and setting a festive tone for the occasion. The students enthusiastically joined in, clapping and singing along. Walking the runway in colourful and creative outfits, the teachers added an element of surprise and excitement, with students eagerly cheering for their mentors. The celebration culminated in a vibrant dance performance, showcasing energetic choreography and synchronized moves, leaving the students applauding in delight.
                                    <br></br>
                                    The event was a delightful tapestry of music, style and creativity, weaving together moments of joy and wonder. It not only honoured the spirit of the children but also beautifully accentuated the deep and enduring bond between teachers and students, transforming the day into a magical and memorable celebration that will linger in everyone’s hearts.
                                    <br></br>

                                </Typography>
                                <Typography
                                    variant={'h6'}
                                    color={'primary'}
                                    align={'center'}
                                    fontWeight={'normal'}
                                ><br />
                                    Every child is a different kind of flower and all together make this world a beautiful garden.
                                    <br />

                                </Typography>
                            </Box>


                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 200 : 100}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {/* <Box >
                                <Typography
                                    variant={'h6'}
                                    color={'primary'}
                                    align={'center'}
                                    fontWeight={'normal'}
                                ><br />
                                    “Where festivities and memories entwine—Childrensday2024 blossoms in vibrant hues!"
                                    <br />

                                </Typography>
                            </Box> */}
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box marginBottom={4}>
                            <Sidebar2024 />
                        </Box>
                        <Box marginBottom={4}>
                            <Archive2022 />
                        </Box>

                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default ChildrensDay2024;